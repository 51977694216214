import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import RootImage from '../components/RootImage'

class Homepage extends React.Component {
  render() {
    const siteTitle = 'Ardsley Reservoir'

    return (
      <Layout>
        <Helmet title={siteTitle} />

        <section id="intro" className="main style2">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>About Ardsley Reservoir</h2>
              </header>
              <p>
                Sometimes known as Tingley Reservoir, East West Ardsley
                Reservoir or West Ardsley Reservoir due to its location between
                Tingley, Woodkirk, and East Ardsley, Ardsley Reservoir is one of
                Yorkshire Water's many reservoirs. Looking out over Emley Moor,
                at 120 acres it is used to provide the area with drinking water,
                as well as the nearby Coca Cola plant.
              </p>
              <p>
                The reservoir is unusual as it is not fed by rivers and streams,
                rather it holds water delivered from other sources. The water
                has travelled sometimes as far as Ripponden and Barnsley!
              </p>
              <p>
                If you are looking to drive and park near Ardsley Reservoir, we
                recommend using the free car park onsite. The postcode is WF3
                1EE, then is is only a short walk to the reservoir.
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/ardsley-reservoir/#introduction"
              >
                More information on Ardsley Reservoir
              </a>
            </div>
            <div className="col-6">
              <span className="image fit">
                <RootImage
                  filename="pic01.jpg"
                  alt="Ardsley Reservoir in Summer"
                />
              </span>
            </div>
          </div>
        </section>

        <section id="things-to-do" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit">
                <RootImage
                  filename="pic02.jpg"
                  alt="Children playing at Ardsley Reservoir"
                />
              </span>
            </div>
            <div className="col-6">
              <header className="major">
                <h2>Things to Do</h2>
              </header>
              <p>
                There is a trail that is used for hiking, walking and running
                that circles the reservoir measuring 2.6 kilometers. It is also
                the perfect spot for exploring with young children, and very
                pushchair friendly.
              </p>
              <p>
                Several exercise groups use Ardsley Reservoir, such as{' '}
                <a
                  href="https://www.runleeds.co.uk/group/woodkirk-valley-wolves/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Woodkirk Valley Wolves
                </a>{' '}
                and Monday Movers.
              </p>
              <p>
                Although it looks like a great place for a summer dip, swimming
                is not permitted due to the strong undercurrent.
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/ardsley-reservoir/#things-to-do"
              >
                More things to do at Ardsley Reservoir
              </a>
            </div>
          </div>
        </section>

        <section id="why" className="main style2">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>Amenities</h2>
              </header>
              <p>
                The reservoir has its own car park, and dogs are welcome but
                must be kept on a lead. The path around the reservoir is
                wheelchair friendly, but be aware that if it has rained there
                may be puddles.
              </p>
              <p>
                There are also bins dotted around, to make it easy to keep the
                area as clean as it can be.
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/ardsley-reservoir/#amenities"
              >
                Amenities at Ardsley Reservoir
              </a>
            </div>
            <div className="col-6">
              <span className="image fit">
                <RootImage
                  filename="pic03.jpg"
                  alt="Dogs allowed at Ardsley Reservoir"
                />
              </span>
            </div>
          </div>
        </section>

        <section id="history" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit">
                <RootImage
                  filename="pic04.jpg"
                  alt="Hare and Hounds Pub Ardsley"
                />
              </span>
            </div>
            <div className="col-6">
              <header className="major">
                <h2>Food and Drink</h2>
              </header>
              <p>
                Although there is nowhere to get food or drink at the reservoir,
                there are several places a short distance away.
              </p>
              <ul>
                <li>Beans coffee shop is a great place to grab a snack</li>
                <li>
                  <a
                    href="https://www.hareandhounds-tingley.co.uk/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Hare and Hounds Pub
                  </a>{' '}
                  in Tingley has a beer garden for those summer afternoons.
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/pg/britishoak2013"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The British Oak
                  </a>{' '}
                  only a short walk away is dog friendly
                </li>
              </ul>
              <p>
                Nothing like a walk, followed by some grub and a drink to end
                the day!
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/ardsley-reservoir/#food-and-drink"
              >
                Food and drink near Ardsley Reservoir
              </a>
            </div>
          </div>
        </section>

        <section id="why" className="main style2">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>History</h2>
              </header>
              <p>
                Made in 1880, Ardsley reservoir is fed mainly from upland
                reservoirs in Ryburn Valley. However following the 1995 drought
                an emergency feed was created linking the reservoir to
                underground aquifers in the east.
              </p>
              <p>
                During World War 2, trees were cut down from the surrounding
                areas and placed across the reservoir to stop seaplanes landing.
              </p>
              <p>
                The reservoir was around long enough to see Ardsley change and
                grow. It could definitely tell a few tales.
              </p>

              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/ardsley-reservoir/#history"
              >
                History of Ardsley Reservoir
              </a>
            </div>
            <div className="col-6">
              <span className="image fit">
                <RootImage
                  filename="pic05.jpg"
                  alt="Remebering Ardsley Reservoir"
                />
              </span>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default Homepage
